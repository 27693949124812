import React, { Component } from "react";
import styled from "styled-components";

import divider from "../library/images/KF2_Xmas_Divider.png";

export default class Divider extends Component {
  render() {
    return (
      <DividerWrapper>
        <img src={divider} alt="Divider" />
      </DividerWrapper>
    );
  }
}

const DividerWrapper = styled.div`
  margin: 0;
  overflow: hidden;

  img {
    height: 6vh;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 40px;
  }

  @media (max-width: 450px) {
    img {
      height: 5vh;
    }
  }
`;
