import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import styled from "styled-components";
import { injectGlobal } from "styled-components";
import Analytics from "react-router-ga";
import ToTop from "react-scroll-top";

import Header from "./components/Header";
import Arrow from "./components/Arrow";
import BuyNowLinks from "./components/BuyNowLinks";
import Trailer from "./components/Trailer";
import ZedConomy from "./components/ZedConomy";
import Divider from "./components/Divider";

import carousel1 from "./library/images/Carousel1.jpg";
import carousel2 from "./library/images/Carousel2.jpg";
import carousel3 from "./library/images/Carousel3.jpg";
import carousel4 from "./library/images/Carousel4.jpg";
import carousel5 from "./library/images/Carousel5.jpg";
import carousel6 from "./library/images/Carousel6.jpg";
import carousel7 from "./library/images/Carousel7.jpg";
import carousel8 from "./library/images/Carousel8.jpg";
import achievement1 from "./library/images/Achievement1.png";
import achievement2 from "./library/images/Achievement2.png";
import achievement3 from "./library/images/Achievement3.png";
import achievement4 from "./library/images/Achievement4.png";
import achievement5 from "./library/images/Achievement5.png";
import achievement6 from "./library/images/Achievement6.png";
import achievement7 from "./library/images/Achievement7.png";
import achievement8 from "./library/images/Achievement8.png";
import achievement9 from "./library/images/Achievement9.png";
import achievement10 from "./library/images/Achievement10.png";

injectGlobal`
@import url('https://fonts.googleapis.com/css?family=Poly');
`;

class App extends Component {
  render() {
    return (
      <Router>
        <WidescreenStyle>
          <SiteWrapper>
            <Header />
            <Analytics id="UA-384258-15" debug>
              <Arrow />
              <BuyNowLinks />
              <TextWrapper>
                <h2>'Tis The Season...</h2>
              </TextWrapper>
              <Trailer />
              <TextWrapper>
                <h2>...For Season's Beatings!</h2>
                <p>
                  It's beginning to look a lot like Christmas...{" "}
                  <span>in Hell!</span> Oh no, it looks as though those{" "}
                  <span>mischevious Zeds</span> have invaded Santa's workshop.
                  There's certainly no question as to whether they have been
                  'naughty' or 'nice' this year!
                </p>
                <p>
                  Luckily, our <span>heroes are here</span> to save the
                  (Christmas) day from complete disaster! And as you may have
                  heard, they have much-needed assistance from a new addition -{" "}
                  <span>none other than Santa himself!</span> Only, this isn't
                  any ordinary Mr. Klaus, because he has been working out (and
                  putting aside those milk and cookies) in order to get his
                  workshop running again. Ladies and gentlemen, we give you none
                  other than <span>Badass Santa!</span> You can catch a glimpse
                  of this (not so jolly) fellow later on...
                </p>
              </TextWrapper>
              <CarouselWrapper>
                <Carousel
                  indicators={false}
                  style={{ height: "100%", width: "100%" }}
                >
                  <Carousel.Item>
                    <img src={carousel7} alt="Santa's Workshop" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={carousel1} alt="Screenshot #1" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={carousel2} alt="Screenshot #2" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={carousel3} alt="Screenshot #3" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={carousel4} alt="Screenshot #4" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={carousel5} alt="Screenshot #5" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={carousel8} alt="Shopping Mall" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={carousel6} alt="Screenshot #6" />
                  </Carousel.Item>
                </Carousel>
              </CarouselWrapper>
              <TextWrapper>
                <p>
                  <span>Badass Santa</span> is a purchasable playable character
                  voiced by legendary actor <span>Gary Busey</span>. Busey also
                  lends his voice to the <span>Trader</span> in the Santa’s
                  Workshop Map – and this <span>“gift of Gary”</span> will be
                  free for all players throughout this Christmas themed event.
                </p>
                <p>
                  As a special holiday treat, we are also introducing the fan
                  favorite community map <span>Shopping Spree</span> for the
                  duration of the{" "}
                  <span>Twisted Christmas: Season’s Beatings update</span>.
                  Experience the true horrors of consumerism as players slay
                  Zeds throughout a nightmarish and mangled shopping mall. Below
                  are the acievement awards for the <span>Shopping Spree</span>{" "}
                  map:
                </p>
              </TextWrapper>
              <IconsWrapper>
                <img src={achievement6} alt="Shopping Spree Achievement 1" />
                <img src={achievement7} alt="Shopping Spree Achievement 2" />
                <img src={achievement8} alt=" Shopping Spree Achievement 3" />
                <img src={achievement9} alt=" Shopping Spree Achievement 4" />
                <img src={achievement10} alt="Shopping Spree Achievement 5" />
              </IconsWrapper>
              <TextWrapper>
                <p>
                  In addition to the <span>Shopping Spree</span> map, players
                  will be introduced to <span>Santa's Workshop</span>. Beat back
                  waves of zeds in this{" "}
                  <span>brand-new objective survival map</span>. Compatible with
                  Weekly, Survival, and Endless modes. Here are the achievement
                  awards for the <span>Santa's Workshop</span> map:
                </p>
              </TextWrapper>
              <IconsWrapper>
                <img src={achievement1} alt="Santa's Workshop Achievement 1" />
                <img src={achievement2} alt="Santa's Workshop Achievement 2" />
                <img src={achievement3} alt="Santa's Workshop Achievement 3" />
                <img src={achievement4} alt="Santa's Workshop Achievement 4" />
                <img src={achievement5} alt="Santa's Workshop Achievement 5" />
              </IconsWrapper>
              <Divider />
              <TextWrapper>
                <h2>Ho Ho Ho... Hell!</h2>
                <p>
                  'Twas the night before Christmas, when all through the house,
                  not a creature was stirring... except for some Zeds of course!
                  Deck the halls with blood and monsters. This year’s winter
                  update marks the return of zesty{" "}
                  <span>Christmas-themed Zeds</span>. So, mark your (advent)
                  calendar and make a date to go (Christmas) shopping for your
                  favorite monsters!
                </p>
              </TextWrapper>
              <CarouselWrapper>
                <Carousel
                  indicators={false}
                  style={{ height: "100%", width: "100%" }}
                >
                  <Carousel.Item>
                    <img
                      src="https://cosmic-s3.imgix.net/f73c1200-e74c-11e8-897c-c3e89add2802-KF2_AC_Husk.png"
                      alt="Husk"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://cosmic-s3.imgix.net/a13914d0-e68d-11e8-93f0-a94cde6f34a7-AC_Gorefast_Lg.jpg"
                      alt="Gorefast"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://cosmic-s3.imgix.net/379fe790-e8d3-11e8-a225-35cc76046a3e-KF2_AC_Clot.png"
                      alt="Clot"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://cosmic-s3.imgix.net/2fd29fb0-e99d-11e8-a67d-ff099f2d4aab-KF2_AC_Scrake.png"
                      alt="Scrake"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://cosmic-s3.imgix.net/b1b44370-eb42-11e8-b8c7-bd5bd7e8403e-KF2_AC_Abomination.png"
                      alt="Abomination"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://cosmic-s3.imgix.net/fb83fd10-ebfb-11e8-b90b-cb15cd011e77-KF2_AC_Patriarch.png"
                      alt="Patriarch"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://cosmic-s3.imgix.net/2da38100-ed8a-11e8-9c45-27c4dcdebb5d-KF2_AC_Stalker.png"
                      alt="Stalker"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://cosmic-s3.imgix.net/635a2d20-ee62-11e8-9f6e-1db55e0c19b5-KF2_AC_Hans.png"
                      alt="Hans"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://cosmic-s3.imgix.net/6c3a4e00-ef2c-11e8-a3c3-150a1884757c-KF2_AC_KFleshpound.png"
                      alt="King Fleshpound"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://cosmic-s3.imgix.net/c3f6a700-f0c7-11e8-96ba-17113d0fed71-KF2_AC_Crawler.png"
                      alt="Crawler"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://cosmic-s3.imgix.net/65ed7560-f187-11e8-ae2c-f1edcdfefdc0-KF2_AC_Cyst.png"
                      alt="Cyst"
                    />
                  </Carousel.Item>
                </Carousel>
              </CarouselWrapper>
              <Divider />
              <TextWrapper>
                <h2>STOCKing Fillers</h2>
                <p>
                  At least some goodies made it out of the workshop before the
                  invasion! Just in time for the season, we have{" "}
                  <span>four new weapons</span> to unwrap. Players can enjoy the
                  return of the <span>Fire Axe</span>, plus the addition of the{" "}
                  <span>M-32 Grenade Launcher</span>, the <span>Thompson</span>,
                  and the <span>Krampus Battle Axe</span>. There might also be
                  another <span>little surprise</span> in store... see if you
                  can spot it!
                </p>
                <p>
                  So, there are plenty of <span>new toys</span> for you to play
                  with. We really hope that you, ahem, put the{" "}
                  <span>"slay"</span> into the <span>sleigh bells</span> this
                  year!
                </p>
              </TextWrapper>
              <CarouselWrapper>
                <Carousel
                  indicators={false}
                  style={{ height: "100%", width: "100%" }}
                >
                  <Carousel.Item>
                    <img
                      src="https://cosmic-s3.imgix.net/0a61a6b0-e74d-11e8-a8c8-ad6b576b471b-KF2_AC_FireAxe.png"
                      alt="Fire Axe"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://cosmic-s3.imgix.net/f5714ef0-e80e-11e8-a0c6-2d64e943bfc2-KF2_AC_Thompson.png"
                      alt="Thompson"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://cosmic-s3.imgix.net/070ab370-ea82-11e8-b8c7-bd5bd7e8403e-KF2_AC_KingBloatAxe.png"
                      alt="King Bloat Axe"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://cosmic-s3.imgix.net/e1916130-ecbf-11e8-bebf-ad3e61ac47b5-KF2_AC_M32.png"
                      alt="M-32"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://cosmic-s3.imgix.net/0c1816b0-f008-11e8-a3c3-150a1884757c-KF2_AC_KrampusAxe.png"
                      alt="Krampus Axe"
                    />
                  </Carousel.Item>
                </Carousel>
              </CarouselWrapper>
              <Divider />
              <ZedConomy />
              <ExtraPadding />
              <ToTop hideAt={160} position="top" />
            </Analytics>
          </SiteWrapper>
        </WidescreenStyle>
      </Router>
    );
  }
}

export default App;

const WidescreenStyle = styled.div`
  background: linear-gradient(#000000, #073a3e, #071f33);
`;

const SiteWrapper = styled.div`
  max-width: 1920px;
  margin: auto;
`;

const TextWrapper = styled.div`
  padding: 60px 20vw 20px 20vw;
  font-family: "Poly", serif;
  font-weight: lighter;
  color: white;
  text-shadow: 0.5px 0.5px black;
  text-align: justify;

  h2 {
    font-family: "NewRocker-Regular", cursive;
    font-size: 62px;
    letter-spacing: 4px;
    text-align: center;
    color: white;
    margin: 20px 0;
    text-shadow: 2px 2px #ff0000;
  }

  p {
    font-size: 16px;
  }

  span {
    font-weight: bold;
    color: #fde704;
    text-shadow: 1px 1px black;
  }

  @media (max-width: 800px) {
    padding: 40px 10vw 10px 10vw;

    h2 {
      font-size: 42px;
    }
  }

  @media (max-width: 650px) {
    padding: 40px 5vw 10px 5vw;

    h2 {
      font-size: 32px;
    }

    p {
      font-size: 13px;
      text-align: left;
    }
  }
`;

const CarouselWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 20vw 20px 20vw;

  img {
    height: 100%;
    width: 100%;
    border: solid;
    border-width: 2px;
    border-color: #fde704;
  }

  @media (max-width: 800px) {
    padding: 10px 10vw 20px 10vw;
  }

  @media (max-width: 650px) {
    padding: 10px 5vw 20px 5vw;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 20vw 10px 20vw;

  img {
    height: 70px;
    margin: 10px;
  }

  @media (max-width: 1000px) {
    img {
      height: 50px;
    }
  }

  @media (max-width: 800px) {
    padding: 10px 10vw 20px 10vw;
  }

  @media (max-width: 650px) {
    padding: 10px 5vw 10px 5vw;
  }

  @media (max-width: 490px) {
    img {
      height: 40px;
    }
  }
`;

const ExtraPadding = styled.div`
  height: 7vh;
`;
