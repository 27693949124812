import React, { Component } from "react";
import styled from "styled-components";

class BuyNowLinks extends Component {
  render() {
    return (
      <LinksWrapper>
        <a
          href="https://store.steampowered.com/app/232090/Killing_Floor_2/"
          target="_blank"
          rel="noopener noreferrer"
          className="buy-link"
        >
          <img
            src="https://cosmic-s3.imgix.net/8def0280-dba8-11e8-b537-b7d9f9fad6ff-SteamLogo.png"
            alt="Steam Store"
          />
        </a>
        <a
          href="https://store.playstation.com/en-us/product/UP0115-CUSA02134_00-KFGAME0000000001"
          target="_blank"
          rel="noopener noreferrer"
          className="buy-link"
        >
          <img
            src="https://cosmic-s3.imgix.net/0a8ff830-e1f3-11e8-9e3a-f3c8a196b06e-PSUS.png"
            alt="Playstation Store US"
          />
        </a>
        <a
          href="https://store.playstation.com/en-gb/product/EP0114-CUSA05179_00-KFGAME0000000001"
          target="_blank"
          rel="noopener noreferrer"
          className="buy-link"
        >
          <img
            src="https://cosmic-s3.imgix.net/7c8c7a90-dba8-11e8-960b-dd870054d9db-PSEU.png"
            alt="Playstation Store Europe"
          />
        </a>
        <a
          href="https://www.microsoft.com/p/killing-floor-2/bsb132zqvwzm?rtc=1&activetab=pivot%3Aoverviewtab"
          target="_blank"
          rel="noopener noreferrer"
          className="buy-link"
        >
          <img
            src="https://cosmic-s3.imgix.net/a05d7c30-dba8-11e8-9351-bf75ddd1f5d4-XboxLogo.png"
            alt="Xbox Store"
          />
        </a>
      </LinksWrapper>
    );
  }
}

export default BuyNowLinks;

const LinksWrapper = styled.div`
  margin-top: -1px;
  background: black;
  padding: 35px 0;
  display: flex;
  justify-content: space-around;

  .buy-link {
    display: flex;
    justify-content: center;
  }

  img {
    background: black;
    width: 70%;
    height: 100%;
    padding: 0.5vw;
    border: solid;
    border-radius: 8px;
    border-width: 1px;
    border-color: rgb(135, 135, 135);
  }

  img:hover {
    border-color: #fde704;
  }

  @media (max-width: 650px) {
    padding: 20px 5vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 60%;
      height: 100%;
      padding: 1vw;
    }

    .buy-link {
      margin: 5px 0;
    }
  }
`;
