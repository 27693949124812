import React, { Component } from "react";
import Headroom from "react-headroom";
import styled from "styled-components";

import arrow from "../library/images/icon-arrow-down.png";

export default class Arrow extends Component {
  render() {
    return (
      <Headroom>
        <ArrowWrapper>
          <img src={arrow} alt="Scroll Down" />
        </ArrowWrapper>
      </Headroom>
    );
  }
}

const ArrowWrapper = styled.div`
  position: absolute;
  left: 46.5vw;
  margin-top: -6vw;

  img {
    height: 5vw;
  }

  @media (max-width: 1024px) {
    img {
      display: none;
    }
  }
`;
