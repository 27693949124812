import React, { Component } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

export default class Trailer extends Component {
  render() {
    return (
      <VideoWrapper>
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url="https://youtu.be/ch_6G2RgOcw"
            width="100%"
            height="100%"
          />
        </div>
      </VideoWrapper>
    );
  }
}

const VideoWrapper = styled.div`
margin-bottom: 20px;
padding: 0 20vw;

@media (max-width: 1608px) {
  padding: 0 15vw;
}

@media (max-width: 1378px) {
  padding: 0 10vw;
}

@media (max-width: 650px) {
  0 5vw;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    border: solid;
    border-width: 2px;
    border-color: #ff0000;
   
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
