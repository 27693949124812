import React, { Component } from "react";
import styled from "styled-components";

import mainLogo from "../library/images/MainLogo.png";
import gameLogo from "../library/images/KF2_Logo.png";

export default class Header extends Component {
  render() {
    return (
      <HeaderWrapper>
        <LogoContainer>
          <a href="http://www.killingfloor2.com/">
            <img src={gameLogo} alt="Killing Floor 2 Logo" />
          </a>
          <img src={mainLogo} className="main-logo" alt="Main Logo" />
        </LogoContainer>
      </HeaderWrapper>
    );
  }
}

const HeaderWrapper = styled.div`
  background-image: url("https://cosmic-s3.imgix.net/6a54b790-d953-11e8-bb9d-3b5a263082ce-KF2_TwistedChristmas.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-basis: auto;
  height: 850px;
  position: relative;
  overflow: hidden;
  padding-bottom: 10px;

  @media (max-width: 800px) {
    height: 500px;
  }

  @media (max-width: 650px) {
    height: 400px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-basis: auto;
  height: 100%;
  width: 100%;
  position: absolute;

  img {
    height: 90px;
  }

  .main-logo {
    margin-top: -20px;
    height: 180px;
  }

  @media (max-width: 800px) {
    img {
      height: 60px;
    }

    .main-logo {
      margin-top: -10px;
      height: 100px;
    }
  }

  @media (max-width: 650px) {
    .main-logo {
      height: 70px;
    }
  }
`;
