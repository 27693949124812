import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import styled from "styled-components";

import Divider from "../components/Divider";
import tradingFloor from "../library/images/TradingFloor.png";
import bundles from "../library/images/Bundles.png";
import crossover1 from "../library/images/Crossover1.jpg";
import crossover2 from "../library/images/Crossover2.jpg";
import crossover3 from "../library/images/Crossover3.jpg";
import crossover4 from "../library/images/Crossover4.jpg";
import xmasTickets from "../library/images/Xmas_Tickets.png";

export default class ZedConomy extends Component {
  render() {
    return (
      <div style={{ marginTop: "60px" }}>
        <ImageContainer>
          <img src={tradingFloor} alt="Trading Floor" />
        </ImageContainer>
        <TextWrapper style={{ paddingTop: "0" }}>
          <h2>A Killer Crossover</h2>
          <p>
            Killing Floor 2 players will gain access to the{" "}
            <span>“Road Redeemer”</span>, a chained bat and favored melee weapon
            of road warriors in <span>Road Redemption</span>. The{" "}
            <span>Road Redeemer</span> is a Tier 2 melee weapon that can be
            found under the <span>Berserker Perk</span>. Console players can
            also take advantage of the upcoming Road Redemption/Killing Floor 2
            crossover later this year with an upcoming update!
          </p>
          <p>
            In turn, <span>Road Redemption</span> players on Steam can now
            experience an exciting crossover with Killing Floor 2! Players who
            own both games will receive access to two new playable characters in
            Road Redemption from Killing Floor 2: <span>Hans Volter</span> and{" "}
            <span>DJ Skully</span>.
          </p>
        </TextWrapper>
        <CarouselWrapper>
          <Carousel
            indicators={false}
            style={{ height: "100%", width: "100%" }}
          >
            <Carousel.Item>
              <img src={crossover1} alt="Crossover #1" />
            </Carousel.Item>
            <Carousel.Item>
              <img src={crossover2} alt="Crossover #2" />
            </Carousel.Item>
            <Carousel.Item>
              <img src={crossover3} alt="Crossover #3" />
            </Carousel.Item>
            <Carousel.Item>
              <img src={crossover4} alt="Crossover #4" />
            </Carousel.Item>
          </Carousel>
        </CarouselWrapper>
        <Divider />
        <TextWrapper>
          <h2>Badder Than Ever</h2>
          <p>
            The{" "}
            <span>Killing Floor 2 - Twisted Christmas: Season's Beatings</span>{" "}
            update promises to end the year with a bang, with the new playable{" "}
            <span>Badass Santa</span> purchasable character voiced by{" "}
            <span>Gary Busey</span> himself! As mentioned before, this isn't
            your average St. Nick, as you can probably see below...
          </p>
        </TextWrapper>
        <ImageContainer2>
          <img
            src="https://cosmic-s3.imgix.net/ef455510-f6fc-11e8-a996-f9301dde455d-KF2_AC_BadassSanta.jpg"
            alt="Badass Santa"
          />
        </ImageContainer2>
        <Divider />
        <TextWrapper>
          <h2>No Duplicate System For Crates And Ticket Drops</h2>
          <p>
            The <span>system now records items granted</span> from a crate, USB,
            or ticket. While the same rarity drop percentages apply, it will be{" "}
            <span>guaranteed to grant</span> one of each cosmetic (ware-variants
            included) until you have the <span>full item set</span>. Once you
            have obtained all the items from a crate, USB, or ticket, it will
            clear the records of that item set and begin recording anew.
          </p>
        </TextWrapper>
        <ImageContainer>
          <img src={xmasTickets} alt="Xmas Tickets" />
        </ImageContainer>
        <Divider />
        <TextWrapper>
          <h2>New DLC Bundles</h2>
          <p>
            The Christmas sales will be starting early this Holiday Season! All
            new miscellaneous bundles will be for sale in-game on{" "}
            <span>Steam</span>, plus the <span>Playstation Store</span> and{" "}
            <span>Xbox Marketplace</span>. Just remember - the{" "}
            <span>more you buy, the more you save</span>.
          </p>
        </TextWrapper>
        <ImageContainer>
          <img src={bundles} alt="Bundles" />
        </ImageContainer>
      </div>
    );
  }
}

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 20vw 0px 20vw;

  img {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 800px) {
    padding: 10px 10vw 20px 10vw;
  }

  @media (max-width: 650px) {
    padding: 10px 5vw 20px 5vw;
  }
`;

const ImageContainer2 = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 20vw 20px 20vw;

  img {
    height: 100%;
    width: 100%;
    border: solid;
    border-width: 2px;
    border-color: #ff0000;
  }

  @media (max-width: 800px) {
    padding: 10px 10vw 20px 10vw;
  }

  @media (max-width: 650px) {
    padding: 10px 5vw 20px 5vw;
  }
`;

const TextWrapper = styled.div`
  padding: 60px 20vw 20px 20vw;
  font-family: "Poly", serif;
  font-weight: lighter;
  color: white;
  text-shadow: 0.5px 0.5px black;
  text-align: justify;

  h2 {
    font-family: "NewRocker-Regular", cursive;
    font-size: 62px;
    letter-spacing: 4px;
    text-align: center;
    color: white;
    margin: 20px 0;
    text-shadow: 2px 2px #ff0000;
  }

  p {
    font-size: 16px;
  }

  span {
    font-weight: bold;
    color: #fde704;
    text-shadow: 1px 1px black;
  }

  @media (max-width: 800px) {
    padding: 40px 10vw 10px 10vw;

    h2 {
      font-size: 42px;
    }
  }

  @media (max-width: 650px) {
    padding: 40px 5vw 10px 5vw;

    h2 {
      font-size: 32px;
    }

    p {
      font-size: 13px;
      text-align: left;
    }
  }
`;

const CarouselWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 20vw 20px 20vw;

  img {
    height: 100%;
    width: 100%;
    border: solid;
    border-width: 2px;
    border-color: #fde704;
  }

  @media (max-width: 800px) {
    padding: 10px 10vw 20px 10vw;
  }

  @media (max-width: 650px) {
    padding: 10px 5vw 20px 5vw;
  }
`;
